import React from "react"
import styled from "styled-components"

import dogImage from "../../images/dog.png"
import appleImage from "../../images/apple.gif"

const RIGHT_DIR = [0, 1]
const LEFT_DIR = [0, -1]
const UP_DIR = [-1, 0]
const DOWN_DIR = [1, 0]

const APPLE = -1

export default class DogGame extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      grid: null,
      dir: [RIGHT_DIR],
      snake: [[1, 1]],
      gameOver: true,
      score: 0,
      lines: 15,
      columns: 15,
      isMobile: false,
    }
    this.buttonPress = this.buttonPress.bind(this)
    this.timer = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.gameOver !== nextState.gameOver) {
      return true
    }
    if (this.state.grid) {
      for (var i = 0; i < this.state.lines; i++) {
        for (var j = 0; j < this.state.columns; j++) {
          if (this.state.grid[i][j] !== nextState.grid[i][j]) {
            return true
          }
        }
      }
    }
    return false
  }

  getRandomFreePosition(grid) {
    let x, y
    do {
      x = Math.floor(Math.random() * this.state.lines)
      y = Math.floor(Math.random() * this.state.columns)
    } while (grid[x][y] !== 0)
    return [x, y]
  }

  moveSnake() {
    if (this.state.gameOver) {
      return
    }
    var new_dir = Array.from(this.state.dir)
    if (new_dir.length > 1) {
      new_dir.shift()
    }
    this.setState({
      dir: new_dir,
    })
    var new_snake = this.state.snake.slice(1)

    var new_grid = Array.from(this.state.grid).map(x => Array.from(x))

    var old_tile = this.state.snake[0]
    var old_aux = new_grid[old_tile[0]][old_tile[1]]
    new_grid[old_tile[0]][old_tile[1]] = 0

    var new_tile = Array.from(this.state.snake[this.state.snake.length - 1])
    var old_head = this.state.grid[new_tile[0]][new_tile[1]]
    new_tile[0] += new_dir[0][0]
    new_tile[1] += new_dir[0][1]
    new_snake.push(new_tile)

    if (new_grid[new_tile[0]] === undefined) {
      this.endGame()
      return
    }
    let grid_val = new_grid[new_tile[0]][new_tile[1]]
    let extra_score = 0
    if (grid_val === undefined || grid_val > 0) {
      this.endGame()
      return
    } else if (grid_val === APPLE) {
      new_grid[old_tile[0]][old_tile[1]] = old_aux
      new_snake = [old_tile].concat(new_snake)
      let new_apple = this.getRandomFreePosition(new_grid)
      new_grid[new_apple[0]][new_apple[1]] = APPLE
      extra_score = 10
    }
    new_grid[new_tile[0]][new_tile[1]] = old_head + 1

    this.setState({
      grid: new_grid,
      snake: new_snake,
      score: this.state.score + extra_score,
    })
  }

  possibleMove(dir1, dir2) {
    if (dir1 === UP_DIR || dir1 === DOWN_DIR) {
      return dir2 === RIGHT_DIR || dir2 === LEFT_DIR
    } else {
      return dir2 === UP_DIR || dir2 === DOWN_DIR
    }
  }

  executeMove(dir) {
    if (this.possibleMove(this.state.dir[this.state.dir.length - 1], dir)) {
      var new_dir = Array.from(this.state.dir)
      new_dir.push(dir)
      this.setState({
        dir: new_dir,
      })
    }
  }

  buttonPress(event) {
    if (event.keyCode < 37 && 40 < event.keyCode) {
      return
    }
    switch (event.keyCode) {
      case 37:
        this.executeMove(LEFT_DIR)
        break
      case 38:
        this.executeMove(UP_DIR)
        break
      case 39:
        this.executeMove(RIGHT_DIR)
        break
      case 40:
        this.executeMove(DOWN_DIR)
        break
      default:
    }
    event.preventDefault()
  }

  componentDidMount() {
    const isMobile =
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    this.setState({
      lines: isMobile ? 10 : 15,
      columns: isMobile ? 10 : 15,
      isMobile: isMobile,
    })

    document.addEventListener("keydown", this.buttonPress, false)
  }

  endGame() {
    clearInterval(this.eventTrigger)
    document.removeEventListener("keydown", this.buttonPress, false)
    this.setState({ gameOver: true })
    this.timer.current.stopTimer()
  }

  componentWillUnmount() {
    this.endGame()
  }

  restartGame() {
    var new_grid = Array(this.state.lines)
      .fill(0)
      .map(x => Array(this.state.columns).fill(0))
    new_grid[1][0] = 2
    new_grid[1][1] = 3
    let x, y
    ;[x, y] = this.getRandomFreePosition(new_grid, this.props)
    new_grid[x][y] = APPLE
    this.setState({
      grid: new_grid,
      dir: [RIGHT_DIR],
      snake: [
        [1, 0],
        [1, 1],
      ],
      gameOver: false,
      score: 0,
    })
    this.eventTrigger = setInterval(this.moveSnake.bind(this), 200)
    document.addEventListener("keydown", this.buttonPress, false)
    if (this.timer.current) {
      this.timer.current.startTimer()
    }
  }

  render() {
    if (this.state.grid === null) {
      return (
        <Body>
          <GameH1>Clique no Doguinho!</GameH1>
          <Doguinho onClick={this.restartGame.bind(this)} />
        </Body>
      )
    }

    var tiles_div = []
    let snake_head = this.state.snake[this.state.snake.length - 1]
    for (var i = 0; i < this.state.lines; i++) {
      var line_divs = []
      for (var j = 0; j < this.state.columns; j++) {
        let css_class = "tile"
        if (this.state.grid[i][j] > 0) {
          css_class += " snake"

          if (
            this.state.grid[i - 1] &&
            Math.abs(this.state.grid[i][j] - this.state.grid[i - 1][j]) === 1
          ) {
            css_class += " snake_up"
          }
          if (
            this.state.grid[i + 1] &&
            Math.abs(this.state.grid[i][j] - this.state.grid[i + 1][j]) === 1
          ) {
            css_class += " snake_down"
          }
          if (
            Math.abs(this.state.grid[i][j] - this.state.grid[i][j + 1]) === 1
          ) {
            css_class += " snake_right"
          }
          if (
            Math.abs(this.state.grid[i][j] - this.state.grid[i][j - 1]) === 1
          ) {
            css_class += " snake_left"
          }
        } else if (this.state.grid[i][j] === -1) {
          css_class += " apple"
        }

        if (i === snake_head[0] && j === snake_head[1]) {
          css_class += " snake_head"
          if (this.state.dir[0] === UP_DIR) {
            css_class += " face_up"
          } else if (this.state.dir[0] === RIGHT_DIR) {
            css_class += " face_right"
          } else if (this.state.dir[0] === LEFT_DIR) {
            css_class += " face_left"
          }
        }

        line_divs.push(<Tile key={j} className={css_class} />)
      }
      tiles_div.push(<TileLine key={i}>{line_divs}</TileLine>)
    }

    var restart_button = null
    if (this.state.gameOver) {
      restart_button = (
        <RestartButton onClick={this.restartGame.bind(this)}>
          De novo!
        </RestartButton>
      )
    }

    var mobile_buttons = null
    if (this.state.isMobile) {
      mobile_buttons = (
        <ArrowButtonContainer>
          <div>
            <ArrowButton onClick={this.executeMove.bind(this, UP_DIR)}>
              &#8593;
            </ArrowButton>
          </div>
          <div>
            <ArrowButton onClick={this.executeMove.bind(this, LEFT_DIR)}>
              &#8592;
            </ArrowButton>
            <ArrowButton onClick={this.executeMove.bind(this, DOWN_DIR)}>
              &#8595;
            </ArrowButton>
            <ArrowButton onClick={this.executeMove.bind(this, RIGHT_DIR)}>
              &#8594;
            </ArrowButton>
          </div>
        </ArrowButtonContainer>
      )
    }

    return (
      <Body>
        {this.state.isMobile ? null : <GameH1>Doguinho cobra!</GameH1>}
        <div id="grid" className="grid">
          {tiles_div}
        </div>
        {mobile_buttons}
        <Footer>
          {restart_button}
          <Timer ref={this.timer} />
          <Score>
            <span>Score: {this.state.score}</span>
          </Score>
        </Footer>
      </Body>
    )
  }
}

class Timer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time: 0,
      isOn: false,
      start: 0,
    }
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
  }

  componentDidMount() {
    this.startTimer()
  }

  startTimer() {
    this.setState({
      isOn: true,
      time: 0,
      start: Date.now(),
    })
    this.timer = setInterval(
      () =>
        this.setState({
          time: Date.now() - this.state.start,
        }),
      1
    )
  }

  stopTimer() {
    this.setState({ isOn: false })
    clearInterval(this.timer)
  }

  render() {
    return (
      <TimerDiv>
        <span>Tempo: {(this.state.time / 1000).toFixed(1) + "s"}</span>
      </TimerDiv>
    )
  }
}

const Doguinho = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;
  background: url(${dogImage}) 0 0 repeat;
  background-size: cover;

  &:hover {
    -webkit-filter: brightness(60%);
    cursor: pointer;
  }
`

const GameH1 = styled.h1`
  font-size: 2em;
  font-weight: bold;
  padding: 30px 0;
`

const Body = styled.div`
  font-family: Verdana, sans-serif;
  background-color: #1d2126;
  color: white;
  text-align: center;
  width: 500px;
  height: 600px;
  margin-top: 30px;
`

const Tile = styled.div`
  display: inline-block;
  height: 30px;
  width: 30px;

  &:first-child {
    border-left: white solid 1px;
  }

  &:last-child {
    border-right: white solid 1px;
  }

  &.snake {
    position: relative;
  }

  &.snake:before {
    border: #ffb123 3px solid;
    border-radius: 15px;
    background-color: #aa860a;
    content: "";
    position: absolute;
    bottom: 3px;
    right: 3px;
    top: 3px;
    left: 3px;
    z-index: +1;
  }

  &.snake.snake_up:before {
    border-top-style: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    top: 0px;
  }

  &.snake.snake_down:before {
    border-bottom-style: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    bottom: 0px;
  }

  &.snake.snake_right:before {
    border-right-style: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    right: 0px;
  }

  &.snake.snake_left:before {
    border-left-style: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    left: 0px;
  }

  &.snake:after {
    border-color: #ffb123;
    border-style: solid;
    background-color: #1d2126;
    position: absolute;
    width: 3px;
    height: 3px;
    z-index: +2;
  }

  &.snake.snake_up.snake_right:after {
    border-width: 0px 0px 3px 3px;
    border-radius: 0px 0px 0px 6px;
    right: 0px;
    top: 0px;
    content: "";
  }

  &.snake.snake_up.snake_left:after {
    border-width: 0px 3px 3px 0px;
    border-radius: 0px 0px 6px 0px;
    left: 0px;
    top: 0px;
    content: "";
  }

  &.snake.snake_down.snake_right:after {
    border-width: 3px 0px 0px 3px;
    border-radius: 6px 0px 0px 0px;
    right: 0px;
    bottom: 0px;
    content: "";
  }

  &.snake.snake_down.snake_left:after {
    border-width: 3px 3px 0px 0px;
    border-radius: 0px 6px 0px 0px;
    left: 0px;
    bottom: 0px;
    content: "";
  }

  &.snake.snake_head {
    background-color: inherit;
    position: relative;
  }

  &.tile.snake.snake_head:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
    z-index: +2;
    background: url(${dogImage}) 0 0 repeat;
    background-size: cover;
    border-style: none;
  }

  &.snake_head.face_up:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.snake_head.face_left:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  &.snake_head.face_right:before {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  &.apple {
    background-image: url(${appleImage});
    background-size: cover;
  }
`

const TileLine = styled.div`
  height: 30px;

  &:first-child .tile {
    border-top: white solid 1px;
  }

  &:last-child .tile {
    border-bottom: white solid 1px;
  }
`

const TimerDiv = styled.div`
  display: inline-block;
  margin-left: 20px;
`

const Score = styled.div`
  display: inline-block;
  margin-left: 20px;
`

const RestartButton = styled.div`
  background-color: green;
  border-radius: 3px;
  border: none;
  display: inline-block;
  padding: 0px 8px;
  text-align: center;
  cursor: pointer;

  &:hover {
    -webkit-filter: brightness(80%);
  }
`

const Footer = styled.div`
  font-family: Verdana, sans-serif;
  font-size: 15px;
  line-height: 30px;
  padding-top: 12px;
`

const ArrowButton = styled.div`
  background-color: gray;
  border-radius: 3px;
  border: none;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-weight: bold;
  touch-action: manipulation;
`

const ArrowButtonContainer = styled.div`
  padding-top: 12px;
`
