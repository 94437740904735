import { useState, useEffect } from "react"

const SMALL_WIDTH = 740

export default function useIsBreakpointSmall() {
  const [isSmall, setIsSmall] = useState(false)

  useEffect(() => {
    setIsSmall(window.innerWidth < SMALL_WIDTH)
    const handleResize = () => {
      setIsSmall(window.innerWidth < SMALL_WIDTH)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return isSmall
}
