import React from "react"
import styled from "styled-components"

import Menu from "./Menu.js"
import CSSReset from "./CSSSReset.js"
import useIsBreakpointSmall from "../hooks/useIsBreakpointSmall.js"

export default function Layout({ children }) {
  const isSmall = useIsBreakpointSmall()

  return (
    <Container isSmall={isSmall}>
      <CSSReset />
      <Menu />
      <ChildrenContainer>{children}</ChildrenContainer>
      <div />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.isSmall ? "column" : "row")};
  font-family: "Open Sans";
  font-style: normal;
  justify-content: space-between;
`
const ChildrenContainer = styled.div`
  padding: 20px;
`
