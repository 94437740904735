import React from "react"
import styled from "styled-components"

import profile_photo from "../../images/profile_photo.jpg"

export default function Menu() {
  return (
    <MenuContainer>
      <ProfilePhotoContainer>
        <ProfilePhoto src={profile_photo} alt="Profile photo" />
        <ProfileName>Gabriel Dalalio</ProfileName>
      </ProfilePhotoContainer>
    </MenuContainer>
  )
}

const MenuContainer = styled.div`
  background-color: #f3f4f8;
  height: 100vh;
  width: 350px;
`
const ProfilePhotoContainer = styled.div`
  margin: 50px auto;
  text-align: center;
  width: 200px;
`

const ProfilePhoto = styled.img`
  border-radius: 50%;
  height: auto;
  width: 200px;
`

const ProfileName = styled.h1`
  font-size: 25px;
  margin-top: 20px;
`
