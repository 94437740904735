import React from "react"
import Layout from "../components/Layout"
import DogGame from "../components/DogGame"

export default function Home() {
  return (
    <Layout>
      <h1>Bem vindo ao meu site aleatorio</h1>
      <DogGame />
    </Layout>
  );
}
